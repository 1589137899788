@font-face {
    font-family: Poppins;
    src: url('../../public/font/Poppins-Light.ttf') format('truetype');
}

.title {
    position: absolute;
    margin: 60px;
    top: 50px;
}

.subtitle {
    position: absolute;
    margin: 60px;
    top: 100px;
}

.links {
    position: absolute;
    margin-left: 600px;
    top: 50px;
}

.links-name {
    position: relative;
    left: 25px;
    bottom: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #636363;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.tilte-text {
    width: 82px;
    height: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20.16px;
    line-height: 30px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.subtitle-text {
    width: 763px;
    height: 273.61px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16.8px;
    line-height: 25px;
    font-feature-settings: 'pnum' on, 'lnum' on;

    /* Pure/.pure-100 */

    color: #000000;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.stepper-side {
    width: 400px;
    position: absolute;
    right: 300px;
    top: 350px;
}

.subtitle-text-stepper {

    height: 273.61px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16.8px;
    line-height: 25px;
    font-feature-settings: 'pnum' on, 'lnum' on;

    /* Pure/.pure-100 */

    color: #000000;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.title-stepper-side {
    
    display: flex;
    justify-content: center;
    /* 📝 Tipografia/.font-size-scale-up-02 */
    text-align: center;
    align-items: center;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20.16px;
    line-height: 30px;
    text-align: right;
    font-feature-settings: 'pnum' on, 'lnum' on;
    margin-left: 130px;
    /* Pure/.pure-100 */

    color: #000000;


    /* Inside auto layout */

   
}