/* P { text-align: center }
H2 { text-align: center } */
#footer-copy {
  background-color: #23427F;
  border: none;
  margin: 0;
  padding: 15px 0;
  font-family: "Libre Baskerville", serif;
}

.copyright {
  color: #d3dde6;
}

.alinhamentoJustificado {
  text-align: justify;
}

.lightweight-accordion {
  border: 1px solid #becebf;
  /* height: 25px; */
  min-width: 500px;
  margin-top: 4px;

}

.lightweight-accordion-title {
  font-weight: bold;
  padding: 10px 12px 10px 18px;
}

@media (prefers-color-scheme: dark) {
  .lightweight-accordion-title {
    font-weight: bold;
    padding: 10px 12px 100px 18px;
    background: #f1f2f6;
  }
}

.lightweight-accordion-body {
  padding-left: 30px;
}

.card {}

.card.content {
  font-size: 0.8rem;
}

.MuiCardHeader-title {
  font-weight: bold !important;
  font-size: 1rem !important;
}

.card.content .MuiCardHeader-title {
  font-size: 1rem;
}

.card.content .MuiCardHeader-subheader {
  font-size: 0.8rem;

}

.card.content p {
  text-align: left;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
/* 
.imagemBack {
  width: 100%; 
  height: 100vh;
  background-image: url("/public/backGround1.jpeg");
  background-repeat: no-repeat;
  margin-bottom: 0px;
} */

.copyright{
  /* background-color: firebrick; */
  text-align: center;
  color: #000;
  font-size: 11px;
  font-weight: 400;
}

.item {

  margin: 20px;
  /* background: tomato; */
  text-align: center;
  font-size: 1.5em;
}