* {
    box-sizing: border-box;
}

li {
    color: #000;
}

ol,
ul {
    list-style: none;
}

.logo-ati {
    width: 70px;
    height: 16.14px;
}

.title-header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24.19px;
    line-height: 36px;
    display: flex;
    align-items: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
}
.box:hover {
    color: rgb(255, 208, 0);
  }
.subtitle-header {
    width: 758px;
    height: 19px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    font-feature-settings: 'pnum' on, 'lnum' on;

    /* Gray/.gray-70 */

    color: #555555;

}

.header-right {
    float: right;
}

.header {
    display: flex;
    justify-content: space-between;
}

.icon-header {
    /* right: 42px; */
    position: absolute;
    /* background-color: brown; */
    color: #1351B4;
}

.icon-search-header {
    position: absolute;
    right: 42px;
    top: 5px;
    color: #1351B4;
}

.icon-search-close {
    position: absolute;
    right: 0px;
    top: 78px;
    color: #1351B4;
}

.fild-text {
   right: 50px;
}

.input-search {
    right: 70px;
    /* position: absolute; */
    top: 0px;
    /* background-color: brown; */
    color: #1351B4;
}