body,
html {
    height: 100%;

}
.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
.grid {
    display: grid;
}

.grid-template-columns-4 {
    grid-template-columns: repeat(3, 1fr);
}

.footer {
    background-color: aquamarine;
    display: grid;
    place-items: end;

    /* é como escrever: */
    align-items: end;
    justify-items: end;
}


.item {

    margin: 20px;
    /* background: tomato; */
    text-align: center;
    font-size: 1.5em;
}

.grid {
    max-width: 400px;
    margin: 0 auto;
    justify-content: center;
    /* border: 1px solid #ccc; */
}

.menu {
    background-color: red;
}

.bubble {
    height: 85%;
}