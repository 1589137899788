.footer {
  display: inline-block;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  background: #f7f7f7;
  color: rgb(187, 31, 31);
  font-weight: 500;
  display: flex;
  justify-content: baseline;
  align-items: center;
  /* background-image: url("/public/backGround1.jpeg"); */
  
  /* gap: 0.2rem; */
}

.copy-right-sec{
  padding: 1.8rem;
    background: #82074a;
    color: #fff;
    text-align: center;
}
.copy-right-sec a{
  color: #fcd462;
    font-weight: 500;
}

.logo-ati-nova {
  position: relative;
  width: 458px;
  height: 105.69px;
  left: 400px;
  top: 20.85px;
}

.sociais {
  margin: 50px;
  justify-content: space-around;
}

.flex-container {
  padding: 0;
  margin: 50;
  list-style: none;
  display: flex;
  margin-top: 10px;
}

.flex-start {
  /* justify-content: flex-start;  */
  margin-left: 20px;
  
}

.flex-item {
  /* background: tomato; */
  padding: 1px;
  width: 60px;
  height: 50px;
  margin: 10px;
  line-height: 5px;
  color: white;
  font-weight: bold;
  font-size: 2em;
  text-align: center;

  margin-top: 120px;
}