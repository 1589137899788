.App {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 70vh; */
  /* width: 1200px; */

}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}